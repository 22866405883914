import React, { useEffect, useState, useRef } from "react";
import Icons from "../miscellaneous/icons/Icons";
import pone from "../components/page-one/PageOne.module.scss";

interface VideoLink {
  link: string;
}

/***************************************
 * VideoPlay
 * exported to Introduction.tsx
 ***************************************/
export default function VideoPlay({ link }: VideoLink) {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [playState, setPlayState] = useState(false);
  const [runsOnce, setRunsOnce] = useState(true);

  const controlList = "play nodownload noremoteplayback";

  useEffect(() => {
    let isMounted = true;

    if (videoRef.current && runsOnce && isMounted) {
      videoRef.current.autoplay = true;
      setRunsOnce(false);
      // console.log("yes");
    }

    return () => {
      isMounted = false;
    };
  }, [videoRef, runsOnce]);

  function onHandlerPlay() {
    if (videoRef.current && !playState) {
      videoRef.current.play();
      setPlayState(true);
    } else if (videoRef.current && playState) {
      videoRef.current.pause();
      setPlayState(false);
    }
  }

  function onHandlerEnded() {
    setPlayState(false);
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
    }
  }
  return (
    <div className={pone.video_container}>
      <video
        ref={videoRef}
        controlsList={controlList}
        controls={false}
        autoPlay
        preload="auto"
        onEnded={onHandlerEnded}
        playsInline // prevents fullscreen video on iPhone
      >
        <source src={link} type="video/mp4" />
      </video>
      <div className={pone.video_play}>
        {playState ? (
          <Icons iconName="pauseIcon" clicked={onHandlerPlay} />
        ) : (
          <Icons iconName="playIcon" clicked={onHandlerPlay} />
        )}
      </div>
    </div>
  );
}
