import React from "react";
import logo from "../../photos&logos/logo.png";

import mn from "./Menu.module.scss";

/***************************
 * Menu
 * exported to Introduction.tsx
 ***************************/
export default function Menu() {
  return (
    <div className={mn.menu_container}>
        <div className={mn.logo_container}>
             <img src={logo} alt="logo" />
        </div>
      
    </div>
  );
}
