import { db } from "./firebase";
import {
  doc,
  getDoc,
  setDoc,
  getDocs,
  addDoc,
  collection,
  query,
  where,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

/*********************************************************
 * Firebase module  with the closure tecnique (encapsulation)
 * Exported to Login.js
 *********************************************************/
export default function FirebaseModule() {
  ///////////////////////////////////////////////////////////////
  // Encapsulation tecnique (Closures) to use in conjunction with
  // the public functions starting in line 83
  // https://firebase.google.com/docs/firestore/query-data/get-data
  async function checkDataFromDatabase(
    pathCollect: string,
    documentId: string,
  ) {  
    if (pathCollect && documentId) {
      const ref = doc(db, `/${pathCollect}/${documentId}`);
      const snapshot = await getDoc(ref);
      return snapshot;
    }
  }
  // https://firebase.google.com/docs/firestore/manage-data/add-data
  async function addDataToDatabase(
    pathCollect: string,
    documentId: string,
    data: any,
  ) {
    if (documentId && pathCollect) {
      // documentID is the document (reference or name) in firebase
      await setDoc(doc(db, pathCollect, documentId), data);
    }
  }
  // ref: https://firebase.google.com/docs/firestore/query-data/queries#simple_queries
  // checks validation from the already data in the database from the id object
  // keyInDatabase works like a hashmap or dictionary, the key will return the value id: 'value'
  async function getDocFromDatabase(
    field: string,
    value: string,
    pathCollect: string,
  ) {
    if (field && value) {
      const docRef = collection(db, pathCollect);
      const q = query(docRef, where(field, "==", value));
      const snapShotQuery = await getDocs(q);
      return snapShotQuery; // returns a promise.then(() => {})... it needs to be iterated again
    }
  }

  /****************************************************
   * gets all the documents based on the condition
   * const docsData = queryshotQuery.docs.map(doc => ({
   * id: doc.id,
   * ...doc.data()}))
   * setData(docsData);
   ***************************************************/

  //https://firebase.google.com/docs/firestore/query-data/get-data#get_all_documents_in_a_collection
  async function getAllDocsFromDatabase(pathCollect: string) {
    if (pathCollect) {
      const ref = await getDocs(collection(db, pathCollect));
      return ref;
    }
  }

  // https://firebase.google.com/docs/firestore/manage-data/add-data#update-data
  async function updateDocToDatabase(
    pathCollect: string,
    documentId: string,
    data: any,
  ) {
    const documentRef = doc(db, pathCollect, documentId);
    const ref = await updateDoc(documentRef, data);
    return ref;
  }
  // creates a unique document id in firebase with addDoc
  // https://firebase.google.com/docs/firestore/manage-data/add-data
  // updates the document with the autogenerated id
  async function addDocWithIDToDatabase(pathCollect: string, data: any) {
    if (pathCollect) {
      const ref = await addDoc(collection(db, pathCollect), data);
      //   const newRef = await setDoc(ref, dataObj);
      const idref = ref.id;
      updateDocToDatabase(pathCollect, idref, { id: idref });
    }
  }

  // https://firebase.google.com/docs/firestore/manage-data/delete-data
  async function deleteDocFromDatabase(
    pathCollect: string,
    documentId: string,
  ) {
    await deleteDoc(doc(db, pathCollect, documentId));
  }

  // https://firebase.google.com/docs/firestore/manage-data/transactions#batched-writes
  // deletes a group of documents based on the conditions after certain amount of time

  ///////////////////////////////////////////////////////////////
  // will invoke an instance of the closure module,
  function checkDataInFirebase(pathCollect: string, documentId: string) {
    return checkDataFromDatabase(pathCollect, documentId);
  }

  function addDataInFirebase(pathCollect: string, value: string, data: any) {
    return addDataToDatabase(pathCollect, value, data);
  }

  function getDocInFirebase(pathCollect: string, field: string, value: string) {
    return getDocFromDatabase(pathCollect, field, value);
  }

  function getAllDocsFromFirebase(pathCollect: string) {
    return getAllDocsFromDatabase(pathCollect);
  }

  function addDocumentWithFirebaseID(pathCollect: string, data: any) {
    return addDocWithIDToDatabase(pathCollect, data);
  }

  function updateDocToFirebase(
    pathCollect: string,
    documentId: string,
    data: any,
  ) {
    return updateDocToDatabase(pathCollect, documentId, data);
  }
  function deleteDocFromFirebase(pathCollect: string, documentId: string) {
    return deleteDocFromDatabase(pathCollect, documentId);
  }

  return {
    checkDataInFirebase,
    addDataInFirebase,
    getDocInFirebase,
    getAllDocsFromFirebase,
    addDocumentWithFirebaseID,
    updateDocToFirebase,
    deleteDocFromFirebase,
  };
}
