import React, { PropsWithChildren } from "react";
import  ReactDOM from "react-dom";
import styles from "./Modal.module.scss";
import { ModalProps } from "./interface";
/*****************************
 * Modal
 ****************************/
export default function Modal({
  children,
  openModal,
}: PropsWithChildren<ModalProps>) {
  if (!openModal) return null;

  return ReactDOM.createPortal(
    <>
      <div className={styles.modal_background} />
      <div className={styles.modal_style_down}>{children}</div>
    </>,
    document.getElementById("portal") as HTMLElement,
  );
}
