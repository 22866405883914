import React, { useState } from "react";
import Modal from "../../../miscellaneous/modal/Modal";
import trianglegreen from "../../../photos&logos/triangle.webp";
import triangleorange from "../../../photos&logos/triangleorange.webp";
import Icons from "../../../miscellaneous/icons/Icons";

import mt from "../PageOne.module.scss";

/*****************************
 * Methodology
 * gst the MCERL infor for thi component
 *****************************/
export default function Methodology() {
  const [openModal, setOpenModal] = useState(false);

  function onHandlerGet() {
    // still don't know the functionlaity
  }
  return (
    <>
      <div className={mt.methodology_container}>
        <div className={mt.methodology_image}>
          <div className={mt.image_title}>
            <h3>Nuestra Metología</h3>
            <h3>&</h3>
            <h3>Objectivos Lingüísticos</h3>
          </div>
        </div>
        <div className={mt.methodology_wrapper}>
          <div className={mt.triangle_container}>
            <img src={trianglegreen} alt="triangle1" />
            <div className={mt.icon_position}>
              <Icons iconName="systemIcon" />
            </div>
            <h3>Metología</h3>         
          </div>
          <div className={mt.triangle_deco_line} />
          <div className={mt.triangle_container_deco}>
            <img src={triangleorange} alt="triangle1" />
            <div className={mt.icon_position}>
              <Icons iconName="objectiveIcon" />
            </div>
            <h3>Objetivos Lingüísticos</h3>
          </div>
          <div className={mt.triangle_deco_line} />
          <div className={mt.triangle_container}>
            <img src={trianglegreen} alt="triangle1" />
            <div className={mt.icon_position}>
              <Icons iconName="reviewsIcon" />
            </div>
            <h3>Testimonials</h3>
          </div>
        </div>
      </div>
      <Modal openModal={openModal}>
        <div>
          <div>menu</div>
          <div>content for catalog</div>
        </div>
      </Modal>
    </>
  );
}
