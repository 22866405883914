import React, { Suspense } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

import Main from "./components/page-one/Main";
function App() {
  return (
    <>
      <Router>
        <Suspense
          fallback={
            <div
              style={{ width: "100%", textAlign: "center", marginTop: "1em" }}
            >
              Loading.....
            </div>
          }
        >
          <Routes>
            <Route path="/" element={ <Main />}/>
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
