import React from "react";
import VideoPlay from "../../../videos/VideoPlay";
import introduction_video from "../../../videos/introduction_video.mp4";
import Menu from "../../menu/Menu";
import introduction01_video from "../../../videos/introduction01_video.mp4";
import it from "../PageOne.module.scss";

/*********************************
 * Introduction 01
 * exported to PageOne.tsx
 **********************************/
export default function Introduction() {
  return (
    <div className={it.introduction_container}>
      <VideoPlay link={introduction_video} />
      <div className={it.introduction_wrapper}>
        <Menu />
      </div>
    </div>
  );
}

// autoPlay={true} loop
