import { FaLongArrowAltRight, FaChalkboardTeacher } from "react-icons/fa";
import { PiStudentFill } from "react-icons/pi";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { RiMiniProgramLine } from "react-icons/ri";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { FaPlay, FaPause } from "react-icons/fa";
import { GiFlagObjective } from "react-icons/gi";
import { SiDassaultsystemes } from "react-icons/si";
import { SiInfoq } from "react-icons/si";
import { MdReviews } from "react-icons/md";
import ic from "./Icons.module.scss";

/*************************
 * functions getIcons()
 ************************/
export function getIcons(iconName: string) {
  switch (iconName) {
    case "rightarrowIcon":
      return <FaLongArrowAltRight className={ic.rightarrow_icon} />;
    case "professorIcon":
      return <PiStudentFill className={ic.professor_teaching_icon} />;
    case "teachingIcon":
      return <FaChalkboardTeacher className={ic.professor_teaching_icon} />;
    case "dollarIcon":
      return <LiaFileInvoiceDollarSolid className={ic.dollar_program_icon} />;
    case "programIcon":
      return <RiMiniProgramLine className={ic.dollar_program_icon} />;
    case "systemIcon":
      return <SiDassaultsystemes className={ic.methodology_icon} />;
    case "infoIcon":
      return <SiInfoq className={ic.methodology_icon} />;
    case "objectiveIcon":
      return <GiFlagObjective className={ic.methodology_icon} />;
    case "reviewsIcon":
      return <MdReviews className={ic.methodology_icon} />;
    case "playIcon":
      return <FaPlay className={ic.play_icon} />;
    case "pauseIcon":
      return <FaPause className={ic.play_icon} />;
    default:
      break;
  }
}
