import React from "react";
import Introduction from "./introduction/Introduction";
import Information from "./information/Information";
import Courses from "./courses/Courses";
import Methodology from "./methodology/Methodology";
import Footer from "./footer/Footer";
/**************************
 * Main
 **************************/
export default function Main() {
  return (
    <div style={{width:"100%"}}>
      <Introduction />
      <Information />
      <Courses />
      <Methodology />
      <Footer />
    </div>
  );
}
