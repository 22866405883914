import React, { useState, useEffect, useCallback } from "react";
import Modal from "../../../miscellaneous/modal/Modal";
import { FN } from "../../../miscellaneous/enums/enums";
import { uniqueId } from "../../../miscellaneous/uniqueId/uniqueId";
import FirebaseModule from "../../../firebase/FirebaseModule";
import { DocumentData } from "firebase/firestore";
import co from "../PageOne.module.scss";

/****************************
 * Courses 03
 * exported to Main
 * create ifor for each program  bsased on the user response
 ****************************/
export default function Courses() {
  const firebaseModule = FirebaseModule();
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState<DocumentData[] | undefined>([]);
  const [runsOnce, setRunsOnce] = useState<Boolean>(true);

  const getData = useCallback(() => {
    const ref = firebaseModule.getAllDocsFromFirebase(FN.courses);
    ref.then((res) => {
      const dd = res?.docs.map((items) => items.data());
      console.log(dd);

      setData(dd);
    });
  }, [firebaseModule]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && runsOnce) {
      getData();
      setRunsOnce(false);
    }

    return () => {
      isMounted = false;
    };
  }, [runsOnce, getData]);

  function onHandlerGetInfo() {
    setOpenModal((prev) => !prev);
  }
  return (
    <>
      <div className={co.courses_container}>
        <div className={co.courses_title}>
          <header>
            <h2>Nuestros cursos y sus precios</h2>
          </header>

          <div className={co.title_separation} />
          <h2>cursos desde ¢15,000 mensual</h2>
        </div>
        <div className={co.courses_info}>
          { data?.map((item) => {
            return (
              <div key={uniqueId()} className={co.courses_info_wrapper}>
                <div className={co.course_image}>
                  <img src={item.url} alt={item.title} />
                </div>
                <div className={co.courses_description}>
                  <h2>{item.title}</h2>
                  <h2 style={{ fontSize: ".9em" }}>{item.hours}</h2>

                  <div style={{ width: "90%" }}>
                    <h4>
                      {item.promo > 0 ? (
                        <span>{`¢${item.price}`}</span>
                      ) : (
                        `¢${item.price}`
                      )}
                    </h4>
                    <h4 key={uniqueId()}>
                      {item.promo > 0 ? `¢${item.promo}` : null}
                    </h4>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Modal openModal={openModal}>
        <div>
          <div>menu</div>
          <div>content</div>
        </div>
      </Modal>
    </>
  );
}
