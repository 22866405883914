import React from "react";
import Icons from "../../../miscellaneous/icons/Icons";
import info from "../PageOne.module.scss";

/******************************
 * Information 02
 ******************************/
export default function Information() {
  return (
    <div className={info.information_container}>
      <header>
        <h2> Por qué somos las 3B que andas buscando</h2>
      </header>

      <div className={info.icons_formation}>
        <div className={info.icons_info_each}>
          <Icons iconName="professorIcon" />
          <h3>Profesores de la UCR y nativos</h3>
        </div>
        <div className={info.icons_info_each}>
          <Icons iconName="programIcon" />
          <h3>Programas certificados: ESL - MCERL</h3>
        </div>
        <div className={info.icons_info_each}>
          <Icons iconName="teachingIcon" />
          <h3>Metodos 100% interactivos</h3>
        </div>
        <div className={info.icons_info_each}>
          <Icons iconName="dollarIcon" />
          <h3>Los mejores precios del mercado: Desde ¢15,000 mensuales</h3>
        </div>
      </div>
    </div>
  );
}
