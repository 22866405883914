import React from "react";
import logo from "../../../photos&logos/logo.png";
import ft from "./Footer.module.scss";
/****************************
 * Footer
 ***************************/
export default function Footer() {
  const year: string = new Date().getFullYear().toString();

  return (
    <div className={ft.footer_container}>
      <div className={ft.footer_wrapper00}>
        <div className={ft.inner}>
          <header>
            <h1>Para mas informacion</h1>
          </header>
        </div>
      </div>
      
      <div className={ft.footer_wrapper01}>
        <div className={ft.inner1}>
          <div className={ft.logo}>
            <img src={logo} alt="logo_alcanza" />
          </div>
          <h6>{`Todos los derechos reservados © ${year}`}</h6>
        </div>
      </div>
    </div>
  );
}
